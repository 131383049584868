
import { Component, Prop, Vue } from "vue-property-decorator";
import { CardData as ICardData } from "@/interfaces/CardData.interface";

@Component
export default class Card extends Vue {
  @Prop() private cardData!: ICardData;
  @Prop() private scrollDisabled?: boolean;
  @Prop() private scrollDisabledCols?: string;
  @Prop() private icons?: boolean;
  @Prop() private squareTiles?: boolean;
  @Prop() private buttonTypeLink?: boolean;
  @Prop({ default: "h3" }) private titleTextHeadingTag!: string;

  get headingType() {
    return this.titleTextHeadingTag;
  }
}

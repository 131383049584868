
import { Component, Prop, Vue } from "vue-property-decorator";
import Card from "@/components/common-ui/Card.vue";
import { Card as ICard } from "@/interfaces/Card.interface";
import { CardData as ICardData } from "@/interfaces/CardData.interface";

@Component({
  components: { Card }
})
export default class ScrollingCards extends Vue {
  @Prop() private cards!: ICard[];
  @Prop() private cardData!: ICardData;
  @Prop() private cardsCustomClass!: string;
  @Prop({ default: "h3" }) private titleTextHeadingTag!: string;

  public $refs!: {
    scrollContainer: HTMLDivElement;
  };

  private pos: { top: number; left: number; x: number; y: number } = {
    top: 0,
    left: 0,
    x: 0,
    y: 0
  };

  private mouseMoveHandler = (e: MouseEvent): void => {
    // How far the mouse has been moved
    const dx = e.clientX - this.pos.x;
    const dy = e.clientY - this.pos.y;
    // Scroll the element
    this.$refs.scrollContainer.scrollTop = this.pos.top - dy;
    this.$refs.scrollContainer.scrollLeft = this.pos.left - dx;
  };

  private mouseUpHandler = (): void => {
    this.$refs.scrollContainer.removeEventListener(
      "mousemove",
      this.mouseMoveHandler
    );
    this.$refs.scrollContainer.removeEventListener(
      "mouseup",
      this.mouseUpHandler
    );
    this.$refs.scrollContainer.style.cursor = "grab";
  };

  private mouseDownHandler = (e: MouseEvent): void => {
    this.pos = {
      // The current scroll
      left: this.$refs.scrollContainer.scrollLeft,
      top: this.$refs.scrollContainer.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY
    };

    this.$refs.scrollContainer.style.cursor = "grabbing";
    this.$refs.scrollContainer.style.userSelect = "none";
    this.$refs.scrollContainer.addEventListener(
      "mousemove",
      this.mouseMoveHandler
    );
    this.$refs.scrollContainer.addEventListener("mouseup", this.mouseUpHandler);
  };

  get headingType() {
    return this.titleTextHeadingTag;
  }
}

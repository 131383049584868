var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"card align-items-center",class:{
    cardNoScroll: _vm.scrollDisabled,
    'card-no-icons': !_vm.icons,
    'square-tiles': _vm.squareTiles
  }},[(this.cardData.icon)?_c('span',{staticClass:"card__card-icon"},[_c('img',{attrs:{"src":this.cardData.icon,"alt":"","aria-hidden":"true"}})]):_vm._e(),(this.cardData.title)?_c(_vm.headingType,{tag:"component",staticClass:"card__card-title",domProps:{"innerHTML":_vm._s(this.cardData.title)}}):_vm._e(),(this.cardData.text)?_c('p',{staticClass:"card__card-content",domProps:{"innerHTML":_vm._s(this.cardData.text)}}):_vm._e(),(
      this.cardData.buttonText && this.cardData.buttonLinkExternal !== true
    )?_c('router-link',{class:[_vm.buttonTypeLink ? 'button-type-link' : 'button--green'],attrs:{"to":{ path: this.cardData.buttonLink },"aria-label":`${_vm.cardData.buttonText} - ${_vm.cardData.ariaLabel}`}},[_vm._v(" "+_vm._s(this.cardData.buttonText)+" ")]):_vm._e(),(
      this.cardData.buttonText && this.cardData.buttonLinkExternal === true
    )?_c('a',{class:[_vm.buttonTypeLink ? 'button-type-link' : 'button--green'],attrs:{"href":this.cardData.buttonLink,"target":"_blank","aria-label":`${_vm.cardData.buttonText} - ${_vm.cardData.ariaLabel}`}},[_vm._v(" "+_vm._s(this.cardData.buttonText)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
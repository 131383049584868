
import { Vue, Prop, Component } from "vue-property-decorator";
import Card from "@/components/common-ui/Card.vue";
import { Card as ICard } from "@/interfaces/Card.interface";
import { CardData as ICardData } from "@/interfaces/CardData.interface";

@Component({
  components: { Card }
})
export default class StaticCards extends Vue {
  @Prop() private cards!: ICard[];
  @Prop() private cardData!: ICardData;
  @Prop() private cardsCustomClass!: string;
  @Prop({ default: "h3" }) private titleTextHeadingTag!: string;

  get headingType() {
    return this.titleTextHeadingTag;
  }
}

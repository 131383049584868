
import { Vue, Component, Prop } from "vue-property-decorator";
import DoubleScroller from "@/components/cards-ui/DoubleScroller.vue";
import ScrollingCards from "@/components/cards-ui/ScrollingCards.vue";
import ScrollingBlogCards from "@/components/cards-ui/ScrollingBlogCards.vue";
import StaticCards from "@/components/cards-ui/StaticCards.vue";
import StaticBlogCards from "@/components/cards-ui/StaticBlogCards.vue";
import { Card as ICard } from "@/interfaces/Card.interface";
import { CardData as ICardData } from "@/interfaces/CardData.interface";

@Component({
  components: {
    DoubleScroller,
    ScrollingCards,
    ScrollingBlogCards,
    StaticCards,
    StaticBlogCards
  }
})
export default class CardShowcase extends Vue {
  @Prop() private cards!: ICard[];
  @Prop() private cardData!: ICardData;
  @Prop() private cardsCustomClass?: string;
  @Prop({ default: "h3" }) private titleTextHeadingTag!: string;

  get headingType() {
    return this.titleTextHeadingTag;
  }
}
